import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContentContainer from "../Core/Containers/ContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const LetterBlockContainer = styled.div`
  margin: 40px auto 170px;

  @media (min-width: ${breakpoints.sm}) {
    padding: 0 30px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin: 90px auto 130px;
  }
`

const Letter = styled.div`
  max-width: 720px;
  margin: auto;
`

const Content = styled.div`
  h2 {
    ${fonts.garamondRegular};
    font-size: 32px;
    margin-bottom: 30px;
    line-height: 1.15;
  }

  p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.33;
    :last-of-type {
      margin-bottom: 0;
    }

    b {
      ${fonts.sofiaProBold};
    }
  }

  ul {
    list-style-type: disc;
    margin-bottom: 20px;

    li {
      margin-left: 40px;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    h2 {
      font-size: 40px;
      letter-spacing: -0.4px;
    }
  }
`

const Signature = styled(GatsbyImage)`
  margin-top: 30px;
  width: 220px;
`

const LetterBlock = (props) => {
  const { content, image } = props
  return (
    <LetterBlockContainer>
      <ContentContainer>
        <Letter>
          <Content>{renderRichText(content)}</Content>
          <Signature image={getImage(image)} alt={"Team Signature"} />
        </Letter>
      </ContentContainer>
    </LetterBlockContainer>
  )
}

export default LetterBlock
