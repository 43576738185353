import React, {Suspense} from "react"
import HeroBlock from "../Core/HeroBlock"
import LetterBlock from "./LetterBlock"
import TitleTextBlock from "./TitleTextBlock"
import GradientBlock from "./GradientBlock"
import Fallback from "../Core/Fallback"

const QuizCtaBlock = React.lazy(() => import("../Core/QuizCtaBlock"))

const PageContents = ({ blocks }) => {
  const isSSR = typeof window === "undefined"
  let pageBlocks = []
  let bodyBlocks = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block
    if (type === "ContentfulHeroBlock") {
      pageBlocks.push(<HeroBlock {...block} key={index} />)
    }
    if (type === "ContentfulBodyBlock") {
      bodyBlocks.push(block)
    }
    if (
      bodyBlocks.length !== 0 &&
      index === blocks.map((el) => el.__typename).lastIndexOf("ContentfulBodyBlock")
    ) {
      pageBlocks.push(<GradientBlock blocks={bodyBlocks} key={index} />)
    }
    if (type === "ContentfulTextImageBlock") {
      pageBlocks.push(<LetterBlock {...block} key={index} />)
    }
    if (type === "ContentfulTitleTextBlock") {
      pageBlocks.push(<TitleTextBlock {...block} key={index} />)
    }
    if (type === "ContentfulQuizCallout") {
      pageBlocks.push(<React.Fragment key={index}>{!isSSR && <Suspense fallback={<Fallback/>}><QuizCtaBlock {...block}  plp bg="vanilla" /></Suspense>}</React.Fragment>)
    }
  })

  return <React.Fragment>{pageBlocks}</React.Fragment>
}

export default PageContents
